import React from 'react'
import { Heading } from '@chakra-ui/react'
import _ from 'lodash'

const Title = ({ value, ...rest }) => (
  <Heading
    textAlign='center'
    size={{
      base: 'md',
      lg: 'xl'
    }}
    fontWeight={_.get(value, 'fontWeight')}
    letterSpacing={_.get(value, 'letterSpacing')}
    whiteSpace='pre-line'
    color='default'
    {...rest}
  >
    {_.join(_.split(value.label, '<br/>'), '\n')}
  </Heading>
)

export default Title
