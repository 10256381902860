import React from 'react'
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack
} from '@chakra-ui/react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'

const ROWS = [
  'firstRow',
  'secondRow',
  'thirdRow',
  'fourthRow',
  'fifthRow',
  'sixthRow',
  'seventhRow'
]

const Comparing = (props) => {
  const renderRows = () => {
    const res = []
    _.forEach(ROWS, rId => {
      const row = _.get(props, rId)
      if (!_.isNil(row)) {
        res.push(
          <Tr key={rId}>
            <Td>
              <Box>
                <Text
                  fontWeight={_.get(row, 'label.title.fontWeight')}
                  letterSpacing={_.get(row, 'label.title.letterSpacing')}
                >
                  {_.get(row, 'label.title.label')}
                </Text>
                <Text
                  fontWeight={_.get(row, 'label.description.fontWeight')}
                  letterSpacing={_.get(row, 'label.description.letterSpacing')}
                >
                  {_.get(row, 'label.description.value')}
                </Text>
              </Box>
            </Td>
            <Td textAlign='center' w={{ base: '100px', lg: 'auto' }} p={{ base: '0', lg: '4' }}>
              {renderColumn(_.get(row, 'firstColumn'))}
            </Td>
            <Td textAlign='center' w={{ base: '100px', lg: 'auto' }} p={{ base: '0', lg: '4' }}>
              {renderColumn(_.get(row, 'secondColumn'))}
            </Td>
          </Tr>
        )
      }
    })
    return res
  }

  const renderColumn = (v) => {
    switch (v) {
      case 'True':
        return (
          <FontAwesomeIcon icon={faCheck} color='green' />
        )
      case 'False':
        return (
          <FontAwesomeIcon icon={faTimes} color='red' />
        )
      default:
        return (
          <Text>{v}</Text>
        )
    }
  }

  return (
    <VStack
      py={{
        base: '16',
        lg: '24'
      }}
      px={{
        base: '4',
        lg: '42'
      }}
      spacing={{
        base: '12',
        md: '16'
      }}
      bg='white'
      maxW={{
        base: '2xl',
        lg: '7xl'
      }}
      mx='auto'
    >
      <Heading
        textAlign='center'
        size={{
          base: 'md',
          lg: 'xl'
        }}
        color='black'
        fontWeight={_.get(props, 'title.fontWeight')}
        letterSpacing={_.get(props, 'title.letterSpacing')}
        whiteSpace='pre-line'
      >
        {_.join(_.split(props.title.label, '<br/>'), '\n')}
      </Heading>
      <TableContainer style={{ emptyCells: 'show' }}>
        <Table variant='simple' whiteSpace='pre-wrap'>
          <Thead>
            <Tr>
              <Th />
              <Th textAlign='center' fontWeight='bold' w={{ base: '100px', lg: 'auto' }} p={{ base: '2.5', lg: '4' }}>
                {_.get(props, 'headerRow.firstColumn')}
              </Th>
              <Th textAlign='center' fontWeight='bold' w={{ base: '100px', lg: 'auto' }} p={{ base: '2.5', lg: '4' }}>
                {_.get(props, 'headerRow.secondColumn')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {renderRows()}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}

export default Comparing
