import * as React from 'react'
import {
  Box,
  Button,
  Heading,
  Img,
  Stack,
  Text,
  Hide,
  useColorModeValue as mode,
  Flex,
  Link
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import _ from 'lodash'

const Hero = ({
  title,
  description,
  exampleGif,
  matterportImage,
  plusImage,
  upstagerImage,
  tryUpstagerButton,
  matterportLink
}) => {
  return (
    <Box as='section' bg='#F7FAFC' pt='24' pb='28'>
      <Box
        maxW={{
          base: '2xl',
          md: '7xl'
        }}
        mx='auto'
        px={{
          base: '6',
          md: '8'
        }}
        pt={{
          base: '0',
          md: '20'
        }}
        pb='24'
      >
        <Stack
          direction={{
            base: 'column',
            lg: 'column'
          }}
          align={{
            lg: 'center'
          }}
          justify='space-between'
        >
          <Box
            flex='1'
            maxW={{
              lg: '100%'
            }}
          >
            <Heading
              as='h1'
              size={{
                base: 'md',
                lg: 'xl'
              }}
              color={mode('black.800', 'black.300')}
              fontWeight={title.fontWeight}
              letterSpacing={title.letterSpacing}
              textAlign='center'
              maxW='100%'
              m='auto'
              whiteSpace='pre-line'
            >
              {_.join(_.split(title.label, '<br/>'), '\n')}
            </Heading>
            <Text
              color={mode('gray.600', 'gray.400')}
              mt='4'
              fontSize='2xl'
              fontWeight={description.fontWeight}
              letterSpacing={description.letterSpacing}
              textAlign='center'
            >
              {description.value}
            </Text>
            <Hide below='lg'>
              <>
                <Stack
                  direction='column'
                  spacing='4'
                  mt='20'
                  align='center'
                >
                  <Link as='a' href={tryUpstagerButton.href}>
                    <Button
                      colorScheme={tryUpstagerButton.colorScheme}
                      variant={tryUpstagerButton.variant}
                      size='lg'
                      minW='200px'
                      height='14'
                      px='8'
                    >
                      {tryUpstagerButton.label}
                    </Button>
                  </Link>
                  <Text color='gray.600'>{tryUpstagerButton.description}</Text>
                  <Link as={GatsbyLink} to={matterportLink.url}>
                    <Button
                      colorScheme='blue'
                      variant='link'
                      size='lg'
                      minW='200px'
                      px='8'
                    >
                      {matterportLink.label}
                    </Button>
                  </Link>
                </Stack>
              </>
            </Hide>
          </Box>
          <Box
            pos='relative'
            w={{
              base: 'full',
              lg: '90%'
            }}
            h={{
              base: 'auto',
              lg: '100%'
            }}
            style={{
              position: 'relative',
              width: '100%'
            }}
          >
            <Box m={{ lg: '20', base: '8' }}>
              <video src={exampleGif.file.url} autoPlay alt='' muted loop />
            </Box>
          </Box>
        </Stack>
        <Flex
          spacing='8'
          direction={{
            lg: 'row',
            base: 'column'
          }}
          align={{
            lg: 'center',
            base: 'center'
          }}
          justify='center'
          style={{ justifyContent: 'center' }}
          mt='12'
        >
          <Img src={matterportImage.file.url} mb='4' style={{ width: '220px' }} alt='' />
          <Img src={plusImage.file.url} width='20px' mr='4' ml='4' mb='4' alt='' />
          <Img src={upstagerImage.file.url} mb='4' style={{ width: '220px' }} alt='' />
        </Flex>
      </Box>
    </Box>
  )
}

export default Hero
