import React from 'react'
import {
  Box,
  Container,
  Heading,
  VStack,
  Stack,
  Text,
  HStack
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'

const Block = ({ block }) => (
  <Stack
    spacing={{
      base: '4',
      md: '5'
    }}
  >
    <HStack spacing='8' align='center'>
      <Box>
        <FontAwesomeIcon icon={faCircleCheck} size='2x' />
      </Box>
      <Stack
        spacing={{
          base: '1',
          md: '2'
        }}
        flex="1"
      >
        <Text
          fontSize={{
            base: 'lg',
            md: 'xl'
          }}
          fontWeight={block?.title?.fontWeight}
          letterSpacing={block?.title?.letterSpacing}
          color='black'
        >
          {block?.title?.label}
        </Text>
        <Text
          color='black'
          fontWeight={block?.description?.fontWeight}
          letterSpacing={block?.description?.letterSpacing}
        >
          {block?.description?.value}
        </Text>
      </Stack>
    </HStack>
  </Stack>
)

export const MarketingBlock = ({
  firstBlock,
  secondBlock,
  thirdBlock,
  title
}) => {
  return (
    <Box as="section" bg="white">
      <Container
        py={{
          base: '16',
          md: '24'
        }}
      >
        <Stack
          spacing={{
            base: '12',
            md: '16'
          }}
          alignItems="center"
        >
          <Stack
            spacing={{
              base: '4',
              md: '5'
            }}
          >
            <Stack spacing="3" w='full'>
              <Heading
                size={{
                  base: 'sm',
                  lg: 'xl'
                }}
                textAlign="center"
                color='black'
                fontWeight={title.fontWeight}
                letterSpacing={title.letterSpacing}
                whiteSpace='pre-line'
              >
                {_.join(_.split(title.label, '<br/>'), '\n')}
              </Heading>
            </Stack>
          </Stack>
          <VStack
            align='start'
            spacing='8'
          >
            <Block block={firstBlock} />
            <Block block={secondBlock} />
            <Block block={thirdBlock} />
          </VStack>
        </Stack>
      </Container>
    </Box>
  )
}

export default MarketingBlock
