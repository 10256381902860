import * as React from 'react'
import useSWR from 'swr'
import { Spinner } from '@chakra-ui/react'

import { fetcher } from '../controllers/contentful'
import Expectations from '../components/Expectations'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Template from '../components/template'
import Demo from '../components/Demo'
import MarketingBlock from '../components/MarketingBlock'
import Comparing from '../components/Comparing'
import CTAButton from '../components/CTAButton'

const IndexPage = () => {
  const { data, error } = useSWR('pageLandingPage', () => fetcher('pageLandingPage'))

  if (error) {
    console.log('Error while loading Contentful data: ' + error)
  }
  if (!data) {
    return (
      <>
        <Seo title="Upstager" />
        <Spinner size="large" />
      </>
    )
  }

  return (
    <Layout>
      <Seo title="UpStager" />
      <Hero {...data.hero} />
      <MarketingBlock {...data.marketingBlock} />
      <Expectations {...data.expectation} />
      <Demo {...data.demoResult} />
      <Comparing {...data.comparing} />
      <CTAButton {...data.ctaButton} id="get-started" />
      <Template {...data.template} />
      <CTAButton {...data.ctaDemo} id="demo" />
      <CTAButton {...data.matterport} id="matterport" bg="white" target="_blank" />
    </Layout>
  )
}

export default IndexPage
