import * as React from 'react'
import {
  Box,
  Heading,
  Show,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react'
import _ from 'lodash'

const Demo = ({
  description,
  title,
  videoUrl
}) => {
  return (
    <Show above='lg'>
      <Box as="section" bg='#F7FAFC' id="demo">
        <Box
          maxW={{
            base: '2xl',
            lg: '7xl'
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8'
          }}
          py={{
            base: '24',
            md: '24'
          }}
        >
          <Stack
            direction={{
              base: 'column',
              lg: 'column'
            }}
            mt="6"
            align={{
              lg: 'center'
            }}
            justify="space-between"
          >
            <Box
              flex="1"
            >
              <Heading
                as="h1"
                size={{
                  base: 'md',
                  lg: 'xl'
                }}
                color={mode('black.800', 'black.300')}
                fontWeight={title.fontWeight}
                letterSpacing={title.letterSpacing}
                textAlign='center'
                whiteSpace='pre-line'
              >
                {_.join(_.split(title.label, '<br/>'), '\n')}
              </Heading>
              <Text
                color={mode('gray.600', 'gray.400')}
                mt="24"
                pt="12"
                pb="28"
                fontSize="lg"
                fontWeight={description.fontWeight}
                letterSpacing={description.letterSpacing}
                textAlign={'center'}
                maxW={{ lg: '60%', base: '100%' }}
                m="auto"
              >
                {description.value}
              </Text>
            </Box>
            <Box
              pos="relative"
              w={{
                base: 'full',
                lg: '90%'
              }}
              h={{
                base: '100%',
                lg: '100%'
              }}
              style={{ paddingBottom: '56%', position: 'relative' }}
            >
              <iframe
                src={videoUrl.value}
                allow="autoplay"
                autoPlay="1"
                className="iframe"
              />
              <Box w="100%" h="100%" />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Show>
  )
}

export default Demo
