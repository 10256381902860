import React from 'react'
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Square,
  Stack,
  Text
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLink,
  faCouch,
  faHouseLaptop,
  faHouseHeart
} from '@fortawesome/pro-light-svg-icons'

const ExpectationBlock = ({ block, icon }) => (
  <Stack
    spacing={{
      base: '4',
      md: '5'
    }}
  >
    <Square
      size={{
        base: '10',
        md: '12'
      }}
    >
      <FontAwesomeIcon icon={icon} size="2x" color="white" />
    </Square>
    <Stack
      spacing={{
        base: '1',
        md: '2'
      }}
      flex="1"
    >
      <Text
        fontSize={{
          base: 'lg',
          md: 'xl'
        }}
        fontWeight={block?.title?.fontWeight}
        letterSpacing={block?.title?.letterSpacing}
        color='white'
      >
        {block?.title?.label}
      </Text>
      <Text
        color='white'
        fontWeight={block?.description?.fontWeight}
        letterSpacing={block?.description?.letterSpacing}
      >
        {block?.description?.value}
      </Text>
    </Stack>
  </Stack>
)

export const Expectations = ({
  firstBlock,
  secondBlock,
  thirdBlock,
  fourthBlock,
  title
}) => (
  <Box as="section" bg="blue.700" mb="4" id='how-it-works'>
    <Container
      py={{
        base: '16',
        md: '24'
      }}
    >
      <Stack
        spacing={{
          base: '12',
          md: '16'
        }}
        alignItems="center"
      >
        <Stack
          spacing={{
            base: '4',
            md: '5'
          }}
          maxW="3xl"
        >
          <Stack spacing="3">
            <Heading
              size={{
                base: 'md',
                lg: 'xl'
              }}
              textAlign="center"
              color='white'
              fontWeight={title.fontWeight}
              letterSpacing={title.letterSpacing}
            >
              {title.label}
            </Heading>
          </Stack>
        </Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 4
          }}
          columnGap={8}
          rowGap={{
            base: 10,
            md: 16
          }}
        >
          <ExpectationBlock block={firstBlock} icon={faLink} />
          <ExpectationBlock block={secondBlock} icon={faCouch} />
          <ExpectationBlock block={thirdBlock} icon={faHouseLaptop} />
          <ExpectationBlock block={fourthBlock} icon={faHouseHeart} />
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
)

export default Expectations
