import React from 'react'
import { Box, Button, Container, VStack, Link } from '@chakra-ui/react'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import Title from './Title'

const CTAButton = (props) => (
  <Box as='section' bg={props.bg ?? '#F7FAFC'} id={props.id}>
    <Container
      py={{
        base: '16',
        md: '24'
      }}
      maxW={{
        base: '2xl',
        md: '7xl'
      }}
    >
      <VStack justify='center' align='center' spacing='4'>
        <Box px={{ base: '4' }}>
          <Title
            fontWeight={_.get(props, 'title.fontWeight')}
            letterSpacing={_.get(props, 'title.letterSpacing')}
            size={{
              base: _.get(props, 'title.sizeMobile', 'md'),
              lg: _.get(props, 'title.sizeDesktop', 'xl')
            }}
            textAlign='center'
            value={_.get(props, 'title')}
          />
        </Box>
        <Box
          sx={{
            marginTop: '4',
            '*': {
              fontWeight: _.get(props, 'description.fontWeight'),
              letterSpacing: _.get(props, 'description.letterSpacing')
            },
            a: {
              color: 'blue.700'
            }
          }}
          textAlign='center'
        >
          <ReactMarkdown
            components={{
              a: ({ node, ...props }) => <a {...props} target='_blank' />
            }}
          >
            {_.get(props, 'description.value')}
          </ReactMarkdown>
        </Box>
        <Link as='a' href={_.get(props, 'linkUrl.value', 'https://customer.upstager.co/')} target={props.target ?? ''}>
          <Button
            variant={_.get(props, 'buttonLabel.variant')}
            colorScheme={_.get(props, 'buttonLabel.colorScheme')}
            size='lg'
            minW='200px'
            height='14'
            px='8'
          >
            {_.get(props, 'buttonLabel.label')}
          </Button>
        </Link>
      </VStack>
    </Container>
  </Box>
)

export default CTAButton
