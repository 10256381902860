import React from 'react'
import {
  Box,
  Heading,
  Img,
  Stack,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { string, shape } from 'prop-types'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'

const Template = ({
  firstCard,
  secondCard,
  thirdCard,
  fourthCard,
  bg
}) => {
  const renderCard = (card, index) => {
    const isOdd = index % 2 === 0
    return (
      <Stack
        key={index}
        direction={{
          base: 'column',
          lg: 'row'
        }}
        spacing={{
          base: '3rem',
          lg: '2rem'
        }}
        mt='32'
        style={{ marginBottom: '185px' }}
        align={{
          lg: 'center'
        }}
        flexDirection={{ lg: isOdd ? 'row-reverse' : 'row', base: 'column-reverse' }}
        justify='space-between'
      >
        <Box
          w={{
            base: '100%',
            lg: '50%'
          }}
          h={{
            base: 'auto',
            lg: '100%'
          }}
        >
          <Img
            src={card.image.file.url}
            alt=''
          />
        </Box>
        <Box
          flex='1'
          maxW={{
            lg: '530px'
          }}
          style={{ marginBottom: '15px' }}
        >
          <Heading
            as='h2'
            size={{
              base: 'md',
              lg: 'xl'
            }}
            color={mode('black.600', 'black.300')}
            fontWeight={card.title.fontWeight}
            letterSpacing={card.title.letterSpacing}
            style={{ fontSize: '48px', lineHeight: '48px' }}
          >
            {card.title.label}
          </Heading>
          <Box
            sx={{
              marginTop: '4',
              '*': {
                fontWeight: card.description.fontWeight,
                letterSpacing: card.description.letterSpacing,
                fontSize: 'lg',
                color: mode('gray.600', 'gray.400')
              },
              ul: {
                paddingLeft: '20px'
              }
            }}
          >
            <ReactMarkdown>
              {card.description.value}
            </ReactMarkdown>
          </Box>
        </Box>
      </Stack>
    )
  }
  return (
    <Box as='section' bg={bg} pt='4' pb='4'>
      <Box
        maxW={{
          base: '2xl',
          md: '7xl'
        }}
        mx='auto'
        px={{
          base: '6',
          md: '8'
        }}
      >
        {_.map([firstCard, secondCard, thirdCard, fourthCard], (c, i) => renderCard(c, i))}
      </Box>
    </Box>
  )
}

Template.propTypes = {
  firstCard: shape({
    fields: shape({
      title: shape({
        label: string,
        fontWeight: string,
        letterSpacing: string
      }),
      description: shape({
        value: string,
        fontWeight: string,
        letterSpacing: string
      }),
      image: shape({
        file: shape({
          url: string
        })
      })
    })
  }),
  secondCard: shape({
    fields: shape({
      title: shape({
        label: string,
        fontWeight: string,
        letterSpacing: string
      }),
      description: shape({
        value: string,
        fontWeight: string,
        letterSpacing: string
      }),
      image: shape({
        file: shape({
          url: string
        })
      })
    })
  }),
  thirdCard: shape({
    fields: shape({
      title: shape({
        label: string,
        fontWeight: string,
        letterSpacing: string
      }),
      description: shape({
        value: string,
        fontWeight: string,
        letterSpacing: string
      }),
      image: shape({
        file: shape({
          url: string
        })
      })
    })
  })
}

export default Template
